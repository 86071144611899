<template>
  <v-container>
    <v-row>
      <v-col style="display: flex">
        <v-btn large icon :to="{ name: 'Empleados' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="text-h4">Detalle de Empleado</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />
    <div v-if="empleado">
      <EmpleadoCard :empleado="empleado"> </EmpleadoCard>
    </div>
  </v-container>
</template>

<script>
import EmpleadoCard from "../components/EmpleadoCard.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "EmpleadoDetalle",
  components: {
    EmpleadoCard,
  },
  data() {
    return {
      empleado: null,
      foto: null,
    };
  },
  props: ["id"],
  created() {
    EmpleadosService.getEmpleado(this.id)
      .then((response) => {
        this.empleado = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
