<template>
  <v-container v-if="dispositivo">
    <ConfirmDialog
      ref="confirmEliminarTokens"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="dialogId"
      @dialog-yes="handleEliminarTokens"
    ></ConfirmDialog>
    <ConfirmDialog
      ref="confirmRemoveLugar"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="dialogId"
      @dialog-yes="handleRemoveLugar"
    ></ConfirmDialog>
    <ConfirmDialog
      ref="confirmRemoveEmpleado"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="dialogId"
      @dialog-yes="handleRemoveEmpleado"
    ></ConfirmDialog>
    <AgregarDispositivoLugaresDialog
      ref="agregarLugaresDialog"
      :dialogSelection="lugares_id"
      :id="dispositivo.id"
      @dialog-yes="handleAddLugares"
    >
    </AgregarDispositivoLugaresDialog>
    <AgregarDispositivoEmpleadosDialog
      ref="agregarEmpleadosDialog"
      :dialogSelection="empleados_id"
      :id="dispositivo.id"
      @dialog-yes="handleAddEmpleados"
    >
    </AgregarDispositivoEmpleadosDialog>
    <v-row>
      <v-col style="display: flex">
        <v-btn large icon :to="{ name: 'Dispositivos' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="text-h4">Detalle de Dispositivo</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />

    <div v-if="dispositivo">
      <DispositivoCard
        :dispositivo="this.dispositivo"
        @eliminar-tokens="handleEliminarTokensDialog"
      >
      </DispositivoCard>
    </div>
    <br />

    <div v-if="lugares">
      <DispositivoLugaresCard
        :lugares="this.lugares"
        @lugar-dispositivo-remove="handleLugarDialog"
        @agregar-lugares="handleAgregarLugaresBtn"
      >
      </DispositivoLugaresCard>
    </div>
    <br />

    <div v-if="empleados && !dispositivo.empleado">
      <DispositivoEmpleadosCard
        :empleados="this.empleados"
        @empleado-dispositivo-remove="handleEmpleadoDialog"
        @agregar-empleados="handleAgregarEmpleadosBtn"
      ></DispositivoEmpleadosCard>
    </div>
  </v-container>
</template>

<script>
import DispositivoCard from "../components/DispositivoCard.vue";
import DispositivoLugaresCard from "../components/DispositivoLugaresCard.vue";
import DispositivoEmpleadosCard from "../components/DispositivoEmpleadosCard.vue";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import AgregarDispositivoLugaresDialog from "../components/AgregarDispositivoLugaresDialog.vue";
import AgregarDispositivoEmpleadosDialog from "../components/AgregarDispositivoEmpleadosDialog.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "DispositivoDetalle",
  components: {
    DispositivoCard,
    DispositivoLugaresCard,
    DispositivoEmpleadosCard,
    ConfirmDialog,
    AgregarDispositivoLugaresDialog,
    AgregarDispositivoEmpleadosDialog,
  },
  data() {
    return {
      dispositivo: null,
      lugares: null,
      lugares_id: [],
      empleados: null,
      empleados_id: [],
      dialogText: "",
      dialogTitle: "",
      dialogYes: "Remover",
      dialogNo: "Cancelar",
      dialogId: 0,
    };
  },
  props: ["id"],
  created() {
    EmpleadosService.getDispositivo(this.id)
      .then((response) => {
        this.dispositivo = response.data;
        //console.log(this.dispositivo);
      })
      .catch((error) => console.log(error));

    EmpleadosService.getDispositivoLugares(this.id)
      .then((response) => {
        this.lugares = response.data.results;
      })
      .catch((error) => console.log(error));

    EmpleadosService.getDispositivoEmpleados(this.id)
      .then((response) => {
        this.empleados = response.data.results;
      })
      .catch((error) => console.log(error));
  },
  methods: {
    lugaresObjectsToIds() {
      this.lugares_id = [];
      this.lugares.forEach((element) => {
        this.lugares_id.push(element.lugar.id);
      });
    },
    empleadosObjectsToIds() {
      this.empleados_id = [];
      this.empleados.forEach((element) => {
        this.empleados_id.push(element.persona.id);
      });
    },

    handleEliminarTokensDialog(id) {
      this.dialogId = id;
      this.dialogTitle = "Eliminar Tokens";
      this.dialogYes = "Eliminar";
      this.dialogText =
        "Si elimina los tokens el dispositivo dejará de estar autenticado y deberá ingresar usuario y contraseña para volver a operar ¿Confirma la operación?";
      this.$refs.confirmEliminarTokens.dialog = true;
    },

    handleEliminarTokens(id) {
      EmpleadosService.postDispositivoClearTokens(id)
        .then((response) => {})
        .catch((error) => console.log(error));
    },

    handleLugarDialog(id) {
      this.dialogId = id;
      this.dialogTitle = "Remover Lugar";
      this.dialogYes = "Remover";
      this.dialogText = "¿Está seguro?";
      this.$refs.confirmRemoveLugar.dialog = true;
    },
    handleRemoveLugar(id) {
      //console.log("TODO: Eliminar lugardispositivo id:" + id);
      EmpleadosService.deleteDispositivoLugar(id)
        .then((response) => {
          // Debemos remover el id de this empleados
          let lugar = this.lugares.find((x) => x.id === id);
          this.lugares.splice(this.lugares.indexOf(lugar), 1);
        })
        .catch((error) => console.log(error));
    },
    handleEmpleadoDialog(id) {
      this.dialogId = id;
      this.dialogTitle = "Remover Empleado";
      this.dialogText = "¿Está seguro?";
      this.dialogYes = "Remover";
      this.$refs.confirmRemoveEmpleado.dialog = true;
    },
    handleRemoveEmpleado(id) {
      //console.log("TODO: Eliminar empleadoDispositivo id:" + id);
      EmpleadosService.deleteDispositivoEmpleado(id)
        .then((response) => {
          // Debemos remover el id de this empleados
          let empleado = this.empleados.find((x) => x.id === id);
          this.empleados.splice(this.empleados.indexOf(empleado), 1);
        })
        .catch((error) => console.log(error));
    },
    handleAgregarLugaresBtn() {
      this.lugaresObjectsToIds();
      this.$refs.agregarLugaresDialog.dialog = true;
    },
    handleAddLugares(lugares) {
      EmpleadosService.postDispositivoLugares(this.dispositivo.id, lugares)
        .then((response) => {
          EmpleadosService.getDispositivoLugares(this.dispositivo.id)
            .then((response) => {
              this.lugares = response.data.results;
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => {
          this.error = error.response.data;
        });
    },
    handleAgregarEmpleadosBtn() {
      this.empleadosObjectsToIds();
      this.$refs.agregarEmpleadosDialog.dialog = true;
    },
    handleAddEmpleados(empleados) {
      EmpleadosService.postDispositivoEmpleados(this.dispositivo.id, empleados)
        .then((response) => {
          EmpleadosService.getDispositivoEmpleados(this.dispositivo.id)
            .then((response) => {
              this.empleados = response.data.results;
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => {
          this.error = error.response.data;
        });
    },
  },
};
</script>
