<template>
  <v-container>
    <v-row>
      <v-col style="display: flex">
        <v-btn large icon :to="{ name: 'Lugares' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="text-h4">Detalle de Lugar</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />

    <div v-if="lugar">
      <LugarCard :lugar="this.lugar"> </LugarCard>
    </div>
  </v-container>
</template>

<script>
import LugarCard from "../components/LugarCard.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "LugarDetalle",
  components: {
    LugarCard,
  },
  data() {
    return {
      lugar: null,
    };
  },
  props: ["id"],
  created() {
    EmpleadosService.getLugar(this.id)
      .then((response) => {
        this.lugar = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
