<template>
  <div v-if="this.empleados">
    <v-card>
      <v-row>
        <v-col cols="8">
          <v-card-title class="person-name"> Empleados </v-card-title>
          <v-card-subtitle
            >Personas que podrán fichar en este dispositivo</v-card-subtitle
          >
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table v-if="empleados.length" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Apellidos</th>
                <th class="text-left">Nombres</th>
                <th class="text-left">Documento</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="empleado in empleados" :key="empleado.id">
                <td>{{ empleado.persona.apellidos }}</td>
                <td>{{ empleado.persona.nombres }}</td>
                <td>{{ empleado.persona.documento_nro }}</td>
                <td>
                  <v-btn
                    small
                    plain
                    color="primary"
                    @click.stop="
                      $emit('empleado-dispositivo-remove', empleado.id)
                    "
                    >Remover</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else class="text-center">
          No hay empleados asociados a este dispositivo. Podrá fichar cualquier
          empleado.
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('agregar-empleados')"
          ><v-icon left darf>mdi-plus</v-icon>Agregar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DispositivoEmpleadosCard",
  data() {
    return {};
  },
  created() {},
  props: {
    empleados: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
