<template>
  <div v-if="this.lugar.nombre">
    <ConfirmDialog
      ref="confirmDialog"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="this.lugar.id"
      @dialog-yes="handleDialogYes"
    ></ConfirmDialog>
    <v-card>
      <v-container>
        <v-alert dense v-if="error" type="error">{{ this.error }}</v-alert>
        <v-row>
          <v-col cols="8">
            <v-card-title class="person-name">
              {{ this.lugar.nombre }}
            </v-card-title>
            <v-card-subtitle>
              {{ this.lugar.descripcion }}
            </v-card-subtitle>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col sm="10" class="">
              <br />
              <p><strong>Latitud: </strong>{{ this.lugar.latitud }}</p>
              <p><strong>Longitud: </strong>{{ this.lugar.longitud }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :href="
              'https://www.google.com/maps/search/?api=1&query=' +
              this.lugar.latitud +
              ',' +
              this.lugar.longitud
            "
            target="_blank"
            ><v-icon left darf>mdi-map-marker</v-icon>Ver en Mapa</v-btn
          >
          <v-btn color="primary" :to="'/lugar/' + this.lugar.id + '/editar'"
            ><v-icon left darf>mdi-pencil</v-icon>Editar</v-btn
          >
          <v-btn color="error" @click="handleDialog"
            ><v-icon left darf>mdi-delete</v-icon>Eliminar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "LugarCard",
  components: { ConfirmDialog },
  data() {
    return {
      dialogTitle: "Confirmar Eliminar",
      dialogText: "¿Desea eliminar el lugar?",
      dialogYes: "Eliminar",
      dialogNo: "Cancelar",
      error: "",
    };
  },
  created() {},
  props: {
    lugar: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleDialog() {
      this.$refs.confirmDialog.dialog = true;
      this.dialogId = this.lugar.id;
    },
    handleDialogYes(id) {
      //console.log(id);
      EmpleadosService.deleteLugar(id)
        .then((response) => {
          this.$router.push({
            name: "Lugares",
          });
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.error = "Error: Registro no encontrado.";
          else if (error.response.status === 405)
            this.error =
              "Error: El registro está protegido. Compruebe que no esté asignado a un dispositivo.";
        });
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
