<template>
  <v-container>
    <v-autocomplete
      v-model="selLugares"
      :items="lugares"
      item-text="nombre"
      item-value="id"
      multiple
      chips
      label="Lugares"
      cache-items
      :loading="loading"
      :search-input.sync="searchLugar"
      no-data-text="No se encontraron resultados"
    >
    </v-autocomplete>
  </v-container>
</template>

<script>
import EmpleadosService from "@/services/EmpleadosService";

export default {
  name: "LugaresAutocomplete",
  components: {},

  data() {
    return {
      formValidity: false,
      searchLugar: null,
      selLugares: null,
      lugares: [],
      loading: false,
      lugaresRules: [(v) => !!v || "Debe seleccionar uno o más lugares."],
    };
  },
  props: {
    seleccion: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.loading = false;
    // if (this.seleccion)
    //   console.log("Creado autocomplete " + this.seleccion),
    //     (this.selLugares = this.seleccion);
  },
  methods: {
    // emitirClick() {
    //   this.$emit("autoriza-click", this.selLugares);
    // },

    querySelections(v) {
      if (v.length < 3) return;
      this.loading = true;
      EmpleadosService.getLugares(v)
        .then((response) => {
          this.lugares = response.data.results;
          // Saco de los lugares los que ya tengo en el listado
          this.seleccion.forEach((element) => {
            let lugar = this.lugares.find((x) => x.id === element);
            if (lugar) this.lugares.splice(this.lugares.indexOf(lugar), 1);
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });

      this.loading = false;
    },
  },
  watch: {
    searchLugar(val) {
      val && val !== this.selLugares && this.querySelections(val);
    },
  },
};
</script>
