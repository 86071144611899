<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5">
        {{ this.dialogTitle }}
      </v-card-title>
      <v-card-text>{{ this.dialogText }}</v-card-text>

      <v-card-text>
        <EmpleadosAutocomplete
          :seleccion="dialogSelection"
          ref="empleadosAutoComplete"
          :multiple="true"
        ></EmpleadosAutocomplete>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="handleNo">
          {{ this.dialogNo }}
        </v-btn>
        <v-btn color="green darken-1" text @click="handleYes">
          {{ this.dialogYes }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EmpleadosAutocomplete from "../components/autocompletes/EmpleadosAutocomplete.vue";

export default {
  name: "AgregarDispositivoEmpleadosDialog",
  components: { EmpleadosAutocomplete },
  data() {
    return {
      dialog: false,
      dialogTitle: "Agregar Empleados",
      dialogText:
        "Seleccione uno o más empleados para relacionar con este dispositivo.",
      dialogYes: "Aceptar",
      dialogNo: "Cancelar",
    };
  },
  created() {},
  props: {
    id: {
      type: Number,
      required: true,
    },
    dialogSelection: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleNo() {
      this.$refs.empleadosAutoComplete.selEmpleados = null;
      this.$refs.empleadosAutoComplete.Empleados = null;
      this.dialog = false;
    },
    handleYes() {
      this.dialog = false;
      let dispEmpleados = [];
      let selEmpleados = this.$refs.empleadosAutoComplete.selEmpleados;
      this.$refs.empleadosAutoComplete.selEmpleados = null;
      this.$refs.empleadosAutoComplete.Empleados = null;

      if (!selEmpleados) return;

      selEmpleados.forEach((element) => {
        console.log(element);
        let dispositivoEmpleado = {
          dispositivo: this.id,
          persona: element,
        };
        dispEmpleados.push(dispositivoEmpleado);
      });
      this.$emit("dialog-yes", dispEmpleados);
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
