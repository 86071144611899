<template>
  <v-container>
    <v-autocomplete
      v-model="selEmpleados"
      :items="empleados"
      :item-text="(item) => item.apellidos + ', ' + item.nombres"
      :filter="empleadosFilter"
      item-value="id"
      multiple
      chips
      label="Empleados"
      cache-items
      :loading="loading"
      :search-input.sync="searchEmpleados"
      no-data-text="No se encontraron resultados"
    >
    </v-autocomplete>
  </v-container>
</template>

<script>
import EmpleadosService from "@/services/EmpleadosService";

export default {
  name: "EmpleadosAutocomplete",
  components: {},

  data() {
    return {
      formValidity: false,
      searchEmpleados: null,
      selEmpleados: null,
      empleados: [],
      loading: false,
      empleadosRules: [(v) => !!v || "Debe seleccionar uno o más empleados."],
    };
  },
  props: {
    seleccion: {
      type: Array,
      required: true,
    },
  },
  created() {},
  methods: {
    querySelections(v) {
      if (v.length < 3) return;
      this.loading = true;
      EmpleadosService.getEmpleados(v)
        .then((response) => {
          this.empleados = response.data.results;
          // Saco de los empleados los que ya tengo en el listad
          this.seleccion.forEach((element) => {
            let empleado = this.empleados.find((x) => x.id === element);
            if (empleado)
              this.empleados.splice(this.empleados.indexOf(empleado), 1);
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });

      this.loading = false;
    },
    empleadosFilter(item, queryText, itemText) {
      const nombres = item.nombres.toLowerCase();
      const apellidos = item.apellidos.toLowerCase();
      const documento = item.documento_nro;
      const legajo = item.legajo;
      const searchText = queryText.toLowerCase();

      return (
        nombres.indexOf(searchText) > -1 ||
        apellidos.indexOf(searchText) > -1 ||
        documento.indexOf(searchText) > -1 ||
        (legajo && legajo.indexOf(searchText) > -1)
      );
    },
  },
  watch: {
    searchEmpleados(val) {
      val && val !== this.selEmpleados && this.querySelections(val);
    },
  },
};
</script>
