<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5">
        {{ this.dialogTitle }}
      </v-card-title>
      <v-card-text>{{ this.dialogText }}</v-card-text>

      <v-card-text>
        <LugaresAutocomplete
          :seleccion="dialogSelection"
          ref="lugaresAutoComplete"
        ></LugaresAutocomplete>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="handleNo">
          {{ this.dialogNo }}
        </v-btn>
        <v-btn color="green darken-1" text @click="handleYes">
          {{ this.dialogYes }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LugaresAutocomplete from "../components/autocompletes/LugaresAutocomplete.vue";

export default {
  name: "AgregarDispositivoLugaresDialog",
  components: { LugaresAutocomplete },
  data() {
    return {
      dialog: false,
      dialogTitle: "Agregar Lugares",
      dialogText:
        "Seleccione uno o más lugares para relacionar con este dispositivo.",
      dialogYes: "Aceptar",
      dialogNo: "Cancelar",
    };
  },
  created() {},
  props: {
    id: {
      type: Number,
      required: true,
    },
    dialogSelection: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleNo() {
      this.$refs.lugaresAutoComplete.selLugares = null;
      this.$refs.lugaresAutoComplete.Lugares = null;
      this.dialog = false;
    },
    handleYes() {
      this.dialog = false;
      let dispLugares = [];
      let selLugares = this.$refs.lugaresAutoComplete.selLugares;
      this.$refs.lugaresAutoComplete.selLugares = null;
      this.$refs.lugaresAutoComplete.Lugares = null;

      if (!selLugares) return;

      selLugares.forEach((element) => {
        console.log(element);
        let dispositivoLugar = {
          dispositivo: this.id,
          lugar: element,
        };
        dispLugares.push(dispositivoLugar);
      });
      this.$emit("dialog-yes", dispLugares);
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
