<template>
  <div v-if="this.dispositivo.nombre">
    <v-card>
      <v-row>
        <v-col cols="8">
          <v-card-title class="person-name">
            {{ this.dispositivo.nombre }}
          </v-card-title>
          <v-card-subtitle>
            {{ this.dispositivo.descripcion }}
          </v-card-subtitle>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col sm="10" class="">
            <br />
            <p>
              <strong>Tipo: </strong>{{ this.dispositivo.get_tipo_display }}
            </p>
            <p>
              <strong>Nombre de Acceso: </strong
              >{{ this.dispositivo.user.username }}
            </p>
            <p div v-if="dispositivo.empleado">
              <strong>Empleado: </strong
              >{{ this.dispositivo.empleado.apellidos }},
              {{ this.dispositivo.empleado.nombres }}
            </p>
            <p>
              <strong>Registrar Geolocalización: </strong
              >{{ this.dispositivo.guardar_geolocalizacion ? "Sí" : "No" }}
            </p>
            <p>
              <strong>Tolerancia Geolocalización: </strong
              >{{ this.dispositivo.tolerancia_geolocalizacion }} Km.
            </p>
            <p>
              <strong>Reconocimiento Facial: </strong>
              {{ this.dispositivo.reconocimiento_facial ? "Sí" : "No" }}
            </p>
            <p>
              <strong>Guardar Foto: </strong
              >{{ this.dispositivo.guardar_foto ? "Sí" : "No" }}
            </p>
            <p>
              <strong>Estado: </strong
              >{{ this.dispositivo.estado ? "Activado" : "Inactivo" }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn disabled color="primary" :to="{ name: 'Dispositivos' }"
          ><v-icon left darf>mdi-pencil</v-icon>Editar</v-btn
        >
        <v-btn
          color="primary"
          :to="{
            name: 'ChangePasswordDispositivo',
            params: { dispositivo_id: this.dispositivo.id },
          }"
          ><v-icon left darf>mdi-key-change</v-icon>Cambiar Contraseña</v-btn
        >
        <v-btn color="primary" @click="$emit('eliminar-tokens', dispositivo.id)"
          ><v-icon left darf>mdi-key-remove</v-icon>Eliminar Tokens</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "DispositivoCard",
  data() {
    return {};
  },
  created() {},
  props: {
    dispositivo: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
