<template>
  <v-card>
    <v-row>
      <v-col cols="8">
        <v-card-title class="person-name"> Lugares </v-card-title>
        <v-card-subtitle
          >Ubicaciones donde el dispositivo podrá registrar fichadas
        </v-card-subtitle>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <div v-if="!this.lugares">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <v-card-text>
        <v-simple-table v-if="lugares.length" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nombre</th>
                <th class="text-left">Latitud</th>
                <th class="text-left">Longitud</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="lugardispositivo in lugares"
                :key="lugardispositivo.id"
              >
                <td>{{ lugardispositivo.lugar.nombre }}</td>
                <td>{{ lugardispositivo.lugar.latitud }}</td>
                <td>{{ lugardispositivo.lugar.longitud }}</td>
                <td>
                  <v-btn
                    small
                    plain
                    color="primary"
                    @click.stop="
                      $emit('lugar-dispositivo-remove', lugardispositivo.id)
                    "
                    >Remover</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else class="text-center">
          No hay lugares asociados a este dispositivo. Podrá fichar en cualquier
          ubicación.
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('agregar-lugares')"
          ><v-icon left darf>mdi-plus</v-icon>Agregar</v-btn
        >
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "DispositivoLugaresCard",
  data() {
    return {};
  },
  created() {},
  props: {
    lugares: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
